<div class="grid-container">
  <div class="left-column">
    <eos-appointment-types-section id="appointment-types-section" />

    <eos-service-options-section
      id="app-service-options-section"
      (insuranceUpdated)="onInsuranceUpdated()"
    />

    <eos-button-pair
      [backLabel]="serviceViewPageLanguage.back"
      [continueLabel]="serviceViewPageLanguage.continue"
      [isContinueDisabled]="isContinueDisabled"
      [hideBackButton]="hideBackButton()"
      (backAction)="backButtonAction()"
      (continueAction)="continueButtonAction()"
    ></eos-button-pair>

    <div class="cardCancelReschedule" *ngIf="practiceCancelReschedule">
      <mat-card>
        <div class="title">
          {{ serviceViewPageLanguage.alreadyHaveAnAppointment | translate }}?
        </div>
        <div class="content">
          {{
            serviceViewPageLanguage.cancelOrReviewYourAppointmentDetails
              | translate
          }}
          <br />
          <br />
          <button
            mat-stroked-button
            color="primary"
            (click)="viewAppointmentButtonAction()"
          >
            {{ serviceViewPageLanguage.viewAppointment | translate }}
          </button>
        </div>
      </mat-card>
    </div>
  </div>

  <div class="right-column">
    <eos-appointment-selections [showLocation]="true" />
  </div>
</div>
