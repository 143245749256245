import { ILanguageModel } from 'src/app/shared/model/ilanguage-model';

const en: ILanguageModel = {
  languageName: 'en',
  landingPage: {
    hi: 'Hi,',
    howWeCanHelpYouToday: 'How can we help you today?',
    bookAnAppointment: 'Book An Appointment',
    rescheduleCancelAppointment: 'Reschedule/Cancel Appointment',
    patientForms: 'Patient Forms',
    next: 'Next',
  },
  locationLayout: {
    hours: 'Hours',
    miles: 'miles',
    selectLocation: 'Select Location',
  },
  serviceView: {
    selectService: 'Select Service',
    continue: 'Continue',
    back: 'Back',
    alreadyHaveAnAppointment: 'Already have an appointment',
    cancelOrReviewYourAppointmentDetails:
      'Cancel or review your appointment details.',
    viewAppointment: 'View Appointment',
  },
  serviceOptions: {
    appointmentWithDoctor: 'Appointment With',
    insuranceCarrier: 'Insurance Carrier',
    noPreferences: 'No Preference',
  },
  upcomingPage: {
    upcomingAppointments: 'Upcoming Appointments',
    searchAnother: 'Search another family member.',
    done: 'Done',
  },
  apptItem: {
    noAppointment: 'You have no upcoming appointment.',
  },
  siteMessage: {
    scheduleAppointment: 'Schedule Appointment',
  },
  home: {
    fullOutPreVisitFormsOnline: 'Fill out pre-visit forms online.',
    stayTunedForExcitingUpdates: 'Stay tuned for exciting updates',
  },
  confirmDate: {
    searchingFirstAvailableAppointment:
      'Searching for first available appointment...',
    selectDateAndTime: 'Select Date and Time',
    noAppointmentAvailable: 'No appointment is available',
    noResultsFound:
      'There are no available appointments in the next 6 months. Please go back and make another selection or contact our office to schedule an appointment.',
    continue: 'Continue',
    back: 'Back',
    officePhone: 'Office Phone',
  },
  patientInfo: {
    yourInformation: 'Your Information',
    additionalInformation: 'Additional Information',
  },
  patientInfoForm: {
    firstName: 'First Name',
    firstNameRequired: 'First Name is required.',
    firstNameLength: 'First Name must be between 2 and 15 characters.',
    firstNamePattern:
      'Names can only contain letters, dash, space, and apostrophe.',
    lastName: 'Last Name',
    lastNameRequired: 'Last Name is required.',
    lastNameLength: 'Last Name must be between 2 and 25 characters.',
    lastNamePattern:
      'Names can only contain letters, dash, space, and apostrophe.',
    address: 'Address',
    addressRequired: 'Address is required.',
    addressLength: 'Address must be between 2 and 35 characters.',
    city: 'City',
    cityRequired: 'City is required.',
    cityLength: 'City must be between 2 and 25 characters.',
    state: 'State',
    stateRequired: 'State is required.',
    zipCode: 'ZIP Code',
    zipCodeRequired: 'ZIP Code is required.',
    zipCodeLength: 'ZIP Code must be 5 characters.',
    dateOfBirth: 'Date of Birth',
    dateOfBirthRequired: 'Date of Birth is required.',
    dateOfBirthNotValid: 'Date of Birth is invalid.',
    birthSex: 'Birth Sex',
    email: 'Email',
    emailRequired: 'Email is required.',
    emailNotValid: 'Enter a valid email address',
    phoneNumberNotValid: 'Enter a valid phone number',
    phoneNumber: 'Phone Number',
    phoneNumberRequired: 'Phone Number is required.',
    howWouldYouLikeToBeNotified: 'How would you like to be notified?',
    textMe: 'Text me (send to phone number provided above)',
    emailMe: 'Email me (send to email provided above)',
    insuranceId: 'Insurance ID (Optional)',
    bookAppointment: 'Book Appointment',
    back: 'Back',
  },
  patientSearchForm: {
    searchAppointment: 'Search Appointment',
    noResultsFound:
      'No appointment found. Please contact the office directly if you need assistance.',
  },
  confirmation: {
    confirmation: 'Confirmation',
    appointmentBooked: 'Your appointment has been booked.',
    errorBookingAppointment:
      'We were unable to schedule your appointment. Please contact the office.',
  },
  errorPage: {
    genericMessage:
      "We're sorry. Our services are experiencing some difficulty at the moment. Please call the office directly.",
  },
  appointmentSelections: {
    locationSection: 'Location',
    serviceSection: 'Service',
    appointmentTimeSection: 'Appointment Time',
    change: 'Change',
  },
};

export default en;
